<template>
  <div class="multivariate-index">
    <div class="multivariate-head">
      <div class="head-item" v-for="(item, i) in headArr" :key="i">
        <img :src="item.img" alt="" />
        <div class="text-box">
          <div v-if="!item.bold" class="item-text">
            {{ item.memo1 }}
          </div>
          <div v-else class="item-text">
            <span>{{ item.memo1 }}</span>
            {{ item.measurement }}
          </div>
          <div class="item-text">
            {{ item.memo2 }}
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="multivariate-content">
      <div>
        <div class="index-title">
          <div>我的方案</div>
          <span @click="$router.push('/clue/plan')">更多</span>
        </div>
        <el-table
          :data="communityList"
          header-row-class-name="tableheader"
          style="width: 100%; margin-top: 10px"
          height="280px"
          :row-style="{ height: 0 + 'px' }"
          :cell-style="{ padding: '6px 0' }"
        >
          <el-table-column prop="id" label="ID" width="100"></el-table-column>
          <el-table-column prop="name" label="方案名"></el-table-column>
          <el-table-column
            prop="create_time"
            label="创建时间"
          ></el-table-column>
        </el-table>
      </div>
      <div>
        <div class="index-title">
          <div>最新群组</div>
          <span @click="$router.push('/search/group')">更多</span>
        </div>
        <el-table
          :data="communityArr"
          header-row-class-name="tableheader"
          style="width: 100%; margin-top: 10px"
          height="280px"
          :row-style="{ height: 0 + 'px' }"
          :cell-style="{ padding: '6px 0' }"
        >
          <el-table-column prop="avatar" label="头像" width="60">
            <template v-slot="scope">
              <img
                width="29px"
                height="29px"
                v-if="scope.row.avatar !== null"
                :src="scope.row.avatar"
                alt=""
              />
              <img
                width="29px"
                height="29px"
                v-else
                src="../../assets/imgs/mrtx.png"
                alt=""
              />
            </template>
          </el-table-column>

          <el-table-column
            v-for="(item, index) in groupHeaderList"
            :key="index"
            :prop="item.prop"
            :label="item.label"
          ></el-table-column>
        </el-table>
      </div>

      <div>
        <div class="index-title">
          <div>最新用户</div>
          <span @click="$router.push('search/user')">更多</span>
        </div>
        <el-table
          :data="accountList"
          header-row-class-name="tableheader"
          style="width: 100%; margin-top: 10px"
          height="280"
          :row-style="{ height: 0 + 'px' }"
          :cell-style="{ padding: '6px 0' }"
        >
          <el-table-column prop="avatar" label="头像" width="60">
            <template v-slot="scope">
              <img
                width="29px"
                height="29px"
                v-if="scope.row.avatar !== null"
                :src="scope.row.avatar"
                alt=""
              />
              <img
                width="29px"
                height="29px"
                v-else
                src="../../assets/imgs/mrtx.png"
                alt=""
              />
            </template>
          </el-table-column>
          <el-table-column
            v-for="(item, index) in userHeaderList"
            :key="index"
            :prop="item.prop"
            :label="item.label"
          ></el-table-column>
        </el-table>
      </div>
      <!-- -->
    </div>
    <div class="multivariate-foot">
      <div>
        <div class="index-title">
          <div>群组用户采集趋势</div>
        </div>
        <div id="groupUserChart"></div>
      </div>
      <div>
        <div class="index-title">
          <div>聊天记录采集趋势</div>
        </div>
        <div id="messageChart"></div>
      </div>
      <div>
        <div class="index-title">
          <div>群组信息采集趋势</div>
        </div>
        <div id="echarts"></div>
      </div>
      <!-- <div>
        <div class="index-title">
          <div>积分变动</div>
          <span @click="$router.push('/detect/points')">更多>></span>
        </div>
        <el-table
          :data="userList"
          header-row-class-name="tableheader"
          style="width: 100%; margin-top: 10px"
          :height="windowsHeight - 720"
          :row-style="{ height: 0 + 'px' }"
          :cell-style="{ padding: '5px 0' }"
        >
          <el-table-column prop="id" label="ID" width="100"></el-table-column>
          <el-table-column prop="owner" label="用户">
            <template slot-scope="scope">
              {{
                scope.row.owner
                  ? scope.row.owner.oauth_account
                    ? scope.row.owner.oauth_account
                    : scope.row.owner.username
                  : null
              }}
            </template>
          </el-table-column>
          <el-table-column prop="points_value" label="积分变动" width="150px">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.points_value > 0" type="success">
                {{ scope.row.points_value }}
              </el-tag>
              <el-tag v-else-if="scope.row.points_value < 0" type="warning">
                {{ scope.row.points_value }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="details" label="详述"></el-table-column>
          <el-table-column prop="remark" label="备注"></el-table-column>
          <el-table-column
            prop="create_time"
            label="变动时间"
            width="300px"
          ></el-table-column>
        </el-table>
      </div> -->
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { getSummary, getCollectionSummary } from '@/api/home.js'
import { getPointsMenu } from '@/api/points'
import { getPlanList } from '@/api/clue'
import { getGroupList, getUserList } from '@/api/whatsApp'

export default {
  data() {
    return {
      noPic: 'this.src="' + require('@/assets/imgs/i12.png') + '"',
      headArr: [
        {
          img: require('../../assets/imgs/t1.png'),
          memo1: '开通日期：2020-03-01',
          // memo2: '结束日期：2020-03-01',
          bold: false
        },
        {
          img: require('../../assets/imgs/f2.png'),
          memo1: 0,
          memo2: '消息总数量',
          alias: 'message_count',
          bold: true,
          measurement: ''
        },
        {
          img: require('../../assets/imgs/f1.png'),
          memo1: 0,
          memo2: '用户总数量',
          alias: 'user_count',
          bold: true,
          measurement: ''
        },
        {
          img: require('../../assets/imgs/f3.png'),
          memo1: 0,
          memo2: '群组总数量',
          alias: 'group_count',
          bold: true,
          measurement: ''
        },
        {
          img: require('../../assets/imgs/f4.png'),
          memo1: '0',
          memo2: '方案总数量',
          alias: 'plan_count',
          bold: true,
          measurement: ''
        },
        {
          img: require('../../assets/imgs/f6.png'),
          memo1: '0',
          memo2: '标签总数量',
          alias: 'tag_count',
          bold: true,
          measurement: ''
        },
        {
          img: require('../../assets/imgs/f5.png'),
          memo1: '0',
          memo2: '采集总数量',
          alias: 'gather_count',
          bold: true,
          measurement: ''
        }
      ],
      communityList: [],
      communityArr: [],
      accountList: [],
      userList: [],
      // 当前页
      current: 1,
      // 每页记录数
      limit: 10,
      // 总记录数
      total: 0,
      groupHeaderList: [
        {
          label: '群组ID',
          prop: 'id'
        },
        {
          label: '群组名称',
          prop: 'name'
        },

        // {
        //   label: '群组简介',
        //   prop: 'desc'
        // },
        {
          label: '创建时间',
          prop: 'creation'
        }
      ],
      userHeaderList: [
        {
          label: 'ID',
          prop: 'id'
        },
        {
          label: '用户名称',
          prop: 'name'
        },

        {
          label: '创建时间',
          prop: 'create_time'
        }
      ]
    }
  },
  filters: {
    capitalize: function(value) {
      if (!value) return ''
      const arr = value.split('+')
      if (Array.isArray(arr)) {
        return arr[0]
      } else {
        return value
      }
    }
  },
  computed: {
    windowsHeight() {
      return this.$store.state.windowData.height
    },
    info() {
      return this.$store.state.user.userInfo
    }
  },

  mounted() {
    // 获取头部6个数据
    this.getTopData()
    this.getCollectionAmount()
    this.getUseData()
    this.getPlanListInfo()
    this.getGroupData()
    this.getUserData()
    // this.getHead()
    // getMessage()
    if (this.info) {
      this.headArr[0].memo1 =
        '开通日期：' + this.info.date_joined.substring(0, 10)
    }

    // this.headArr[0].memo2 = '结束日期：' + this.info.endDate
  },
  methods: {
    // 获取顶部数据
    async getTopData() {
      const res = await getSummary()
      for (const item in res) {
        this.headArr.forEach(title => {
          if (title.alias === item) {
            title.memo1 = res[item]
          }
        })
      }
    },
    // 获取使用次数
    async getUseData() {
      const res = await getPointsMenu()
      this.userList = res.items

      // console.log('5555', res)s
    },
    // 我的方案
    async getPlanListInfo() {
      const res = await getPlanList()
      this.communityList = res.items
    },
    // 群组数据
    async getGroupData() {
      const res = await getGroupList()
      this.communityArr = res.items
    },
    // 用户
    async getUserData() {
      const res = await getUserList()
      this.accountList = res.items
    },

    async getCollectionAmount() {
      const res = await getCollectionSummary()
      this.getEchartsBar(
        'groupUserChart',
        res.x_axis,
        res.y_axis_group,
        res.y_axis_user
      )
      this.getMessageEchartsBar('messageChart', res.x_axis, res.y_axis_message)
      this.getEcharts('echarts', res.x_axis, res.y_axis_group_link)
    },
    goPath(path) {
      this.$router.push(path)
    },

    getEchartsBar(dom, dataX, dataGroup, dataUser) {
      const chart = echarts.init(document.getElementById(dom))
      const userMax = Math.max.apply(null, dataUser)
      const groupMax = Math.max.apply(null, dataGroup)
      const userInterval = Math.ceil(userMax / 5)
      const groupInterval = Math.ceil(groupMax / 5)
      const userCharMax = userInterval * 5
      const groupCharMax = groupInterval * 5

      const option = {
        legend: {
          data: ['群组', '用户']
        },
        grid: {
          x: 40,
          y: 40,
          x2: 50,
          y2: 60,
          borderWidth: 10
        },
        xAxis: {
          type: 'category',
          data: dataX,
          splitLine: { show: false }
        },
        yAxis: [
          {
            type: 'value',
            // name: '群组',
            min: 0,
            max: groupCharMax,
            interval: groupInterval,
            splitLine: { show: true }
          },
          {
            type: 'value',
            // name: '用户',
            min: 0,
            max: userCharMax,
            interval: userInterval,

            splitLine: { show: true }
          }
        ],
        // tooltip: {
        //   // 提示框组件
        //   trigger: 'item', // 触发类型,'item'数据项图形触发，主要在散点图，饼图等无类目轴的图表中使用。 'axis'坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用。
        //   triggerOn: 'mousemove', // 提示框触发的条件,'mousemove'鼠标移动时触发。'click'鼠标点击时触发。'mousemove|click'同时鼠标移动和点击时触发。'none'不在 'mousemove' 或 'click' 时触发
        //   showContent: true, // 是否显示提示框浮层
        //   alwaysShowContent: false, // 是否永远显示提示框内容
        //   showDelay: 0, // 浮层显示的延迟，单位为 ms
        //   hideDelay: 100, // 浮层隐藏的延迟，单位为 ms
        //   enterable: false, // 鼠标是否可进入提示框浮层中
        //   confine: false, // 是否将 tooltip 框限制在图表的区域内
        //   transitionDuration: 0.4, // 提示框浮层的移动动画过渡时间，单位是 s,设置为 0 的时候会紧跟着鼠标移动
        //   // position: ["50%", "50%"], //提示框浮层的位置，默认不设置时位置会跟随鼠标的位置,[10, 10],回掉函数，inside鼠标所在图形的内部中心位置，top、left、bottom、right鼠标所在图形上侧，左侧，下侧，右侧，
        //   // formatter: "{b0}: {c0}<br />{b1}: {c1}", //提示框浮层内容格式器，支持字符串模板和回调函数两种形式,模板变量有 {a}, {b}，{c}，{d}，{e}，分别表示系列名，数据名，数据值等
        //   backgroundColor: 'transparent', // 标题背景色
        //   borderColor: '#ccc', // 边框颜色
        //   borderWidth: 0, // 边框线宽
        //   padding: 5 // 图例内边距，单位px  5  [5, 10]  [5,10,5,10]
        // },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        series: [
          {
            name: '群组',
            type: 'bar',
            data: dataGroup,
            smooth: true,
            barWidth: 40,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            },
            itemStyle: {
              borderRadius: [0, 0, 0, 0],
              color: new echarts.graphic.LinearGradient(
                0,
                1,
                0,
                0,
                [
                  {
                    offset: 1,
                    color: '#00bfa5'
                  },
                  {
                    offset: 0,
                    color: '#128c7e'
                  }
                ],
                false
              )
            }
          },
          {
            name: '用户',
            type: 'line',
            yAxisIndex: 1,
            data: dataUser,
            smooth: true
          }
        ]
      }
      window.onresize = function() {
        chart.resize()
      }
      chart.setOption(option)
    },
    getMessageEchartsBar(dom, dataX, dataMessage) {
      const chart = echarts.init(document.getElementById(dom))

      const option = {
        legend: {
          data: ['消息']
        },
        grid: {
          x: 60,
          y: 20,
          x2: 0,
          y2: 20
          // borderWidth: 10
        },
        xAxis: {
          type: 'category',
          data: dataX,
          splitLine: { show: false }
        },
        yAxis: [
          {
            type: 'value',
            splitLine: { show: true }
          }
        ],

        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        series: [
          {
            name: '消息',
            type: 'line',
            data: dataMessage,
            smooth: true
          }
        ]
      }
      window.onresize = function() {
        chart.resize()
      }
      chart.setOption(option)
    },
    getEcharts(dom, dataX, dataGroup) {
      const chart = echarts.init(document.getElementById(dom))

      const option = {
        grid: {
          x: 50,
          y: 20,
          x2: 20,
          y2: 20,
          borderWidth: 10
        },
        xAxis: {
          type: 'category',
          data: dataX,
          splitLine: { show: false }
        },
        yAxis: [
          {
            type: 'value',
            splitLine: { show: true }
          }
        ],

        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        series: [
          {
            type: 'bar',
            data: dataGroup,
            smooth: true,
            barWidth: 40,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            },
            itemStyle: {
              borderRadius: [0, 0, 0, 0],
              color: new echarts.graphic.LinearGradient(
                0,
                1,
                0,
                0,
                [
                  {
                    offset: 1,
                    color: '#00bfa5'
                  },
                  {
                    offset: 0,
                    color: '#128c7e'
                  }
                ],
                false
              )
            }
          }
        ]
      }
      window.onresize = function() {
        chart.resize()
      }
      chart.setOption(option)
    },
    currentChange(val) {
      this.current = val
      this.getcountChangeLogs()
    },
    sizeChange(val) {
      this.current = 1
      this.limit = val
      this.getcountChangeLogs()
    },
    // 获取方案列表
    getPage(type) {
      return new Promise((resolve, reject) => {
        // const obj = {
        //   page: 1,
        //   page_size: 8,
        //   collection_type: type
        // }
        // getScheme(obj).then(res => {
        //   if (res) {
        //     resolve(res)
        //   }
        // })
      })
    },
    // 用户数据
    getwhatsappList() {},
    // 使用记录
    getcountChangeLogs() {}
  }
}
</script>

<style lang="scss" scoped>
.multivariate-index {
  width: 100%;
  height: calc(100vh - 50px);
  padding: 10px 20px 20px 20px;
  background: rgb(240, 242, 245);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .multivariate-head {
    width: 100%;
    height: 141px;
    background: white;
    border-radius: 5px;
    display: flex;
    box-shadow: 0 1px 3px 0 #d8d9d9;
    margin-bottom: 10px;
    .head-item {
      width: calc(100% / 7 - 1px);
      border-right: 1px solid #f2f4f8;
      display: flex;
      flex-direction: column;
      justify-content: center;
      overflow: hidden;
      &:nth-last-child(1) {
        border-right: none;
      }
      img {
        height: auto;
        object-fit: cover;
        align-self: center;
      }
      .text-box {
        align-self: center;
        margin-top: 8px;
        text-align: center;
        .item-text {
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          line-height: 24px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          span {
            font-size: 22px;
          }
        }
      }
    }
  }
  .multivariate-content {
    // height: 389px;
    height: calc(50% - 90px);
    margin: 10px 0;
    margin-top: 0;
    display: flex;
    justify-content: space-between;
    > div {
      width: 33%;
      height: 100%;
      background: #ffffff;
      border-radius: 5px;
      padding: 5px;
      overflow: hidden;
      box-shadow: 0 1px 3px 0 #d8d9d9;
    }
  }
  .multivariate-foot {
    width: 100%;
    // height: calc(100% - 410px);
    height: calc(50% - 90px);
    height: 40%;
    display: flex;
    justify-content: space-between;
    > div {
      width: calc(33% - 1px);
      height: 100%;
      background: white;
      border-radius: 5px;
      overflow: hidden;
      padding: 5px;
      box-shadow: 0 1px 3px 0 #d8d9d9;
    }
    #groupUserChart {
      width: 100%;
      height: 100%;
    }
    #messageChart {
      width: 100%;
      height: calc(100% - 40px);
    }
    #echarts {
      width: 100%;
      height: calc(100% - 40px);
    }
  }

  .index-title {
    display: flex;
    justify-content: space-between;
    text-indent: 9px;
    font-size: 14px;
    font-weight: 400;
    color: #4a4a4a;
    line-height: 24px;
    border-bottom: 1px solid #f4f4f4;
    padding: 5px 0;
    font-weight: 600;
    span {
      color: #7c7b7b;
      cursor: pointer;
      &:hover {
        color: rgb(11, 106, 214);
      }
    }
  }
  // ::v-deep .el-table__header .el-table__cell {
  //   background: rgb(243, 247, 249);
  // }
  ::v-deep .tableheader th {
    background: rgb(243, 247, 249);
    padding: 0;
    height: 35px;
  }
  .table-cell-img {
    width: 28px;
    height: 28px;
    object-fit: cover;
    vertical-align: middle;
  }
}
</style>
