import request from '@/plugins/axios'

// 统计概括
export function getSummary(params) {
    return request({
        url: '/dashboard/summary',
        method: 'get',
        params
    })
}

// 采集概括
export function getCollectionSummary(params) {
    return request({
        url: '/dashboard/collection_summary',
        method: 'get',
        params
    })
}
